// body.nectar{
//   font-family: 'Roboto Slab', serif;
// }

h1.nectar {
  font-weight: 700;
  font-size: 60px;
  font-family: 'Raleway', sans-serif;
  background-color: #4169a4;
}

h4.nectar {
  font-family: 'Raleway', sans-serif;
  color: #282828;
}

div.nectar {
  height: 422px;
  width: 750px;
  background: url('../../images/nectar/man_blue.jpg') no-repeat;
  position: relative;
}

.nectar_button {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  border: 2px solid black;
  background-color: #4169a4;
  position: absolute;
  color: white;
  &:hover{
      border: 2px solid white;
      background-color: lighten(#4169a4, 10%);
  }
}

.nectar_button_green {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  border: 2px solid black;
  background-color: #18d26e;
  position: absolute;
  color: white;
  &:hover{
      border: 2px solid white;
      background-color: lighten(#18d26e, 10%);
  }
}

.nectar_transbox {
  padding: 15px;
  margin-top: 180px;
  background-color: white;
  opacity: 0.8;
  color: black;
  span {
    color: black;
  }
}

.nectar_icon {
  font-size: 1rem;
}
