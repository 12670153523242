// body.expansil{
//   font-family: 'Roboto Slab', serif;
// }

h1.expansil {
  font-weight: 700;
  font-size: 60px;
  font-family: 'Raleway', sans-serif;
  background-color: #00a3d1;
}

h4.expansil {
  // font-weight: 700;
  // font-size: 60px;
  font-family: 'Raleway', sans-serif;
  color: #282828;
}

div.expansil {
  height: 550px;
  width: 750px;
  background: url('../../images/expansil/pro5.jpg') no-repeat;
  position: relative;
}

.expansil_button {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  border: 2px solid black;
  background-color: #00a3d1;
  position: absolute;
  color: white;
  margin-top: 150px;
  &:hover{
      border: 2px solid #00a3d1;
      background-color: lighten(#00a3d1, 10%);
  }
}

.expansil_button_green {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  border: 2px solid black;
  background-color: #18d26e;
  position: absolute;
  color: white;
  margin-top: 150px;
  &:hover{
      border: 2px solid white;
      background-color: lighten(#18d26e, 10%);
  }
}

.transbox {
  padding: 15px;
  margin-top: 10px;
  background-color: red;
  opacity: 0.8;
  color: black;
  span {
    color: black;
  }
}

.expansil_icon {
  font-size: 1rem;
}
