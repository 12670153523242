@import "./colors.scss";
@import "campaigns/womanizer.scss";
@import "campaigns/nectar.scss";
@import "campaigns/nutri_lab_burner";


.unseen {
  display: none;
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}

.text-dark-gray{
  color: $dark-gray;
}

.text-yellow {
  color: yellow;
}

.text-green {
  color: green;
}

.text-gold {
  color: gold;
}

.text-yellow-on-dark-gray {
  color: yellow;
  background-color: $dark-gray;
}

.text-red-on-dark-gray {
  color: red;
  background-color: $dark-gray;
}

.text-white-on-dark-gray {
  color: white;
  background-color: $dark-gray;
}

.text-black-on-yellow {
  color: black;
  background-color: yellow;
}

.text-black-on-white {
  color: black;
  background-color: white;
}
