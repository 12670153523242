/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
*/


@import "./vendor/bootstrap/css/bootstrap.min.css";
@import "./vendor/font-awesome/css/font-awesome.min.css";
@import "./vendor/lib/animate/animate.min.css";
@import "./vendor/lib/ionicons/css/ionicons.min.css";
@import "./vendor/lib/owlcarousel/assets/owl.carousel.min.css";
@import "./vendor/lib/lightbox/css/lightbox.min.css";

@import "./style.scss";
@import "./campaign.scss";
@import "./campaigns.scss";
@import "./campaigns/womanizer.scss";
@import "./campaigns/nectar.scss";
@import "./campaigns/expansil.scss";

//@import "./vendor/"
