//@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC:ital,wght@0,400;0,700;1,400;1,700&display=swap');
*{
 font-family: 'Playfair Display SC', serif;
 }

h1 {
  // font-weight: 700;
  // font-size: 60px;
}

.nutri_lab_container_transp {
  width: 750px;
  position: relative;
  height: 550px;
//  background: url( '../../images/nutri/nutrigo/750_500/nutri_background_transp.png') no-repeat;
  background-color: $dark-gray;
// background-color: red;
  margin: 0 auto;
  float: none;
  top: 0px;
}

.top_image {
  position: relative;
  top: 0px;
}
.bottom_image {
  position: fixed;
  top: 460px;
}
.nutri-button {
  position: absolute;
  bottom: 110px;
}

.text-box {
  padding: 15px;
  margin: 15px;
}

.nutri_lab_container {
  width: 750px;
  position: relative;
  height: 550px;
  background: url( '../../images/nutri/nutrigo/750_500/nutri_background.jpg') no-repeat;
  background-color: $dark-gray;
  margin: 0 auto;
  float: none;
  top: 0px;
  .darktext {
    padding-top: 10px;
    color: $dark-gray;
    text-align: left;
    font-weight: 700;
    line-height: 1;
    p {
      font-weight: normal;
      font-size: 20px;
    }
  }

  .top_image {
    position: relative;
    top: 0px;
  }
  .bottom_image {
    position: fixed;
    top: 460px;
  }
  .nutri-button {
    position: absolute;
    bottom: 110px;
  }
}
