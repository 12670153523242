//@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
//@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap');
*{
    //font-family: 'Open Sans', sans-serif;
//    font-family: 'Playfair Display', serif;

//font-family: 'Tangerine';
//font-family: 'Charm', cursive;
font-family: 'Raleway', sans-serif;
}

.btn-womanizer {
  color: #fff;
  font-weight: bold;
  background-color: #857650;
  border-color: #857650;
}

.btn-success:hover {
  color: #fff;
  font-weight: bold;
  background-color: #857650;
  border-color: #857650;
}

.womanizer_container {
  width: 750px;
  position: relative;
  height: 550px;
  background: url('../../images/womanizer/background.jpg') no-repeat;
  margin: 0 auto;
  float: none;
  top: 0px;
  border: 1px solid #D5557D;
  background-size: cover;
  color: #D5557D;
  .whitetext {
    padding-top: 20px;
    color: $white;
    padding-left: 40px;
    text-align: left;
    font-weight: 700;
    line-height: 1;
    h1 {
      font-family: 'Playfair Display', serif;
      margin-left: 0px;
      text-align: left;
      border: 1 solid $white;
    }
    h2 {
      float: right;
    }
    p {
  font-weight: normal;
  font-size: 20px;
    }
  }

  .bottom-button {
    position: absolute;
    bottom: 15px;
  }
}
