// Place all the styles related to the Campaigns controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
//@import "./colors.scss";


.jumbotron {
  margin-top: 20px;
  padding: 0px;
  max-width: 750px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.jumbo_background {
  background-size: cover;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background-blend-mode: screen;
}

.banner_campaign{
  padding: 0px;
  max-width: 750px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.samsung_s10{
  padding: 15px;
  //  background: url( "~samsung_bg") no-repeat;
  color: white;
  background-size: cover;
}


.Mallorca_2{
  padding: 15px;
  //  background: url( "~mallorca_2.jpg") no-repeat;
  color: white;
  background-size: cover;
}


.Mallorca_3{
  padding: 15px;
  //  background: url( "~mallorca_3.jpg") no-repeat;
  color: white;
  background-size: cover;
  h1, h2, h3, h4, h5, p, li {
    color: rgb(73,45,58);
  }
}

.Mallorca_4{
  padding: 15px;
  //  background: url( "~mallorca_4.jpg") no-repeat;
  color: white;
  background-size: cover;
  // h1, h2, h3, h4, h5, p, li {
  //   color: rgb(54,77,74);
  // }
}

.Mallorca_5{
  padding: 15px;
  //  background: url( "~mallorca_5.jpg") no-repeat;
  color: white;
  background-size: cover;
  h1, h2, h3, h4, h5, p, li {
    color: rgb(73,45,58);
  }
}
